<template>
  <div></div>
</template>

<script>
import api from "@/util/api";
import signalR from "@/util/signalR";

export default {
  name: "WhatsAppUnreadCounts",
  props: {
    inboxids: {
      required: true,
    },
  },
  data() {
    return {
      signalRWhatsAppConnection: null,
    };
  },
  mounted() {
    if (this.inboxids.length > 0) {
      this.startSignalRConnection();
    }
  },
  methods: {
    async getUnreadConversationCount(inboxId) {
      const unreadCount = await api.get(
        `whatsappinbox/${inboxId}/unreadConversationCount`
      );
      window.eventBus.dispatchEvent("cw-update-whatsapp-unread-count", {
        inboxId,
        unreadCount,
      });
    },
    startSignalRConnection() {
      if (this.signalRWhatsAppConnection) {
        this.signalRWhatsAppConnection.stop();
      }
      this.signalRWhatsAppConnection = signalR.connectToHub("whatsappws");

      this.signalRWhatsAppConnection.on("WhatsAppConversationUpdated", () => {
        this.inboxids.forEach((inboxId) => {
          this.getUnreadConversationCount(inboxId);
        });
      });

      this.signalRWhatsAppConnection.start().then(() => {
        this.inboxids.forEach((inboxId) => {
          this.getUnreadConversationCount(inboxId);
        });
      });
    },
  },
  watch: {
    inboxids: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.startSignalRConnection();
        }
      },
      deep: true,
    },
  },
};
</script>
