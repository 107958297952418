<template>
  <div class="scheduled-message d-flex flex-column">
    <div class="d-flex flex-column flex-lg-row">
      <p class="time">{{ scheduleTime }}</p>
      <div class="card flex-grow-1 min-width">
        <div class="d-flex justify-content-between">
          <div class="mr-2 mb-2 d-flex min-width">
            <div class="text-truncate mr-2 font-size-16">
              {{
                !this.isSendLater
                  ? scheduledMessage.name
                  : "Reply to " + scheduledMessage.firstRecipient
              }}
            </div>
            <div>
              <div v-show="scheduledMessage.wasProcessed" class="sent-tag">
                Sent
              </div>
            </div>
          </div>
          <b-dropdown
            v-if="scheduledMessage.canEdit && !scheduledMessage.wasProcessed"
            class="text-muted d-flex card-menu"
            variant="white"
            no-caret
            right
          >
            <template v-slot:button-content>
              <MgIcon name="ellipsis-v" scale="1" class="text-muted"></MgIcon
              ><span class="sr-only">Action Menu</span>
            </template>
            <b-dropdown-item-button @click="edit">Edit</b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button v-b-modal="`sm-${scheduledMessage.id}`">
              <span class="text-danger">Delete</span>
            </b-dropdown-item-button>
            <b-modal :id="`sm-${scheduledMessage.id}`">
              <template v-slot:modal-header>
                <h3 class="mb-0">Delete Message</h3>
                <button
                  @click="hideModal(scheduledMessage.id)"
                  class="btn-icon close"
                  aria-label="Close"
                >
                  <MgIcon name="times" scale="1"></MgIcon>
                </button>
              </template>
              <h4 class="my-4 text-danger">
                Are you sure you want to delete and unschedule this message?
              </h4>
              <template v-slot:modal-footer>
                <b-button
                  variant="danger"
                  @click="unscheduleMessage(scheduledMessage.id)"
                >
                  Delete
                </b-button>
                <b-button
                  variant="light"
                  class="float-right"
                  @click.prevent="hideModal(scheduledMessage.id)"
                >
                  Cancel
                </b-button>
              </template>
            </b-modal>
          </b-dropdown>
        </div>
        <div class="d-flex mb-3">
          <div class="gravatar-container">
            <div
              class="profile-letter-container"
              :style="{
                backgroundColor: gravatarBackgroundColor,
              }"
            >
              <div class="profile-letter">
                <div v-if="isPersonalInbox">{{ userName.slice(0, 1) }}</div>
                <div v-if="sharedInbox">
                  {{ sharedInbox.name.slice(0, 1) }}
                </div>
              </div>
            </div>
            <img
              v-if="isPersonalInbox"
              class="gravatar rounded-circle"
              :src="`https://www.gravatar.com/avatar/${hashEmail(
                this.userEmail
              )}?r=pg&d=blank`"
              alt="Name - Team"
            />
          </div>
          <div class="d-flex flex-column">
            <div v-if="isPersonalInbox">
              <strong>{{ userNameDisplay }}</strong>
              <span v-if="scheduledMessage.sendAsStaff">
                - Send as assigned user</span
              >
              <span v-if="scheduledMessage.restrictToAssignment">
                - Send to my contacts only</span
              >
            </div>
            <div v-if="sharedInbox">
              <strong>{{ sharedInbox.name }}</strong>
              - Scheduled by
              {{ userNameDisplay }}
            </div>
            <div>
              <span
                v-show="!(totalSegments && deletedSegments === totalSegments)"
                >to
              </span>
              <span v-show="connectFilterName">
                Filter -
                <em>{{ connectFilterName }}</em>
              </span>
              <span v-show="radiusTargetName">
                Target -
                <em>{{ radiusTargetName }}</em>
              </span>
              <span v-show="slateQueryName">
                Slate Query -
                <em>{{ slateQueryName }}</em>
              </span>
              <span v-show="reNxtListName">
                RE Nxt List -
                <em>{{ reNxtListName }}</em>
              </span>
              <span
                v-show="
                  scheduledMessage.recipientCount > 0 &&
                  !scheduledMessage.segmentHistory
                "
              >
                <em>{{ recipientNames }}</em>
                <span v-show="scheduledMessage.recipientCount > 1">
                  &amp;&nbsp;{{ scheduledMessage.recipientCount - 1 }}
                  more
                </span>
                (<a href v-on:click.prevent="saveCsv()"
                  >{{ scheduledMessage.recipientCount }} recipient<span
                    v-if="scheduledMessage.recipientCount > 1"
                    >s</span
                  ></a
                >)
              </span>
              <span
                v-if="totalSegments > 0 && totalSegments !== deletedSegments"
              >
                <em>{{
                  automatedSegmentNames !== ""
                    ? automatedSegmentNames
                    : segmentNames
                }}</em>
                <span v-if="automatedSegmentNames !== ''">
                  (<a href v-on:click.prevent="saveCsv()"
                    >{{ totalSegments - deletedSegments }} segment<span
                      v-if="totalSegments - deletedSegments > 1"
                      >s</span
                    ></a
                  >)</span
                >
                <span class="segments-deleted" v-if="deletedSegments > 0"
                  ><MgIcon name="exclamation-circle" scale=".75"></MgIcon>
                  {{ deletedSegments }} deleted segment<span
                    v-if="deletedSegments > 1"
                    >s</span
                  ></span
                >
              </span>
              <span
                class="segments-deleted"
                v-if="totalSegments > 0 && totalSegments === deletedSegments"
              >
                <MgIcon name="exclamation-circle" scale=".75"></MgIcon>
                Segment<span v-if="totalSegments > 0">s</span>
                deleted - message will not be sent
              </span>
            </div>
          </div>
        </div>
        <p v-if="scheduledMessage.smartMessage" class="pt-2 mb-2 font-size-16">
          Smart Message
        </p>
        <p v-if="scheduledMessage.smartMessage" class="font-size-14 mb-2">
          <a
            href
            @click.prevent="viewSmartMessage(scheduledMessage.smartMessage)"
          >
            {{ scheduledMessage.smartMessage.name }}
          </a>
          •
          {{
            scheduledMessage.smartMessage.messageType === 0
              ? "Yes or No"
              : scheduledMessage.smartMessage.messageType === 1
              ? "Numeric Scale"
              : "Multiple Choice"
          }}
        </p>
        <div
          class="contents px-4 py-3"
          :class="{
            'media-with-text':
              media &&
              scheduledMessage.messageText &&
              scheduledMessage.messageText !== '<Multimedia Message>',
          }"
        >
          <div v-if="scheduledMessage.smartMessage">
            <span v-if="scheduledMessage.smartMessage.messageType !== 2">{{
              scheduledMessage.smartMessage.message +
              " " +
              scheduledMessage.smartMessage.messageClosingContent
            }}</span>
            <span v-if="scheduledMessage.smartMessage.messageType === 2">
              <div>{{ scheduledMessage.smartMessage.message }}</div>
              <div class="smart-message-choices">
                <div
                  v-for="(choice, index) in scheduledMessage.smartMessage
                    .replyOptions"
                  :key="'choice' + index"
                >
                  {{ choice.replyKeyword }}:
                  {{ choice.replyKeywordDescription }}
                </div>
              </div>
              <div>
                {{ scheduledMessage.smartMessage.messageClosingContent }}
              </div>
            </span>
          </div>
          <div v-else>
            {{ scheduledMessage.messageText }}
          </div>
          <MessageMedia v-if="media" :media="media"></MessageMedia>
        </div>
        <div v-if="scheduledMessage.campaignId" class="mt-2">
          <CampaignTag :campaign-id="scheduledMessage.campaignId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "md5";
import moment from "moment";
import MgIcon from "@/components/MgIcon";
import CampaignTag from "@/components/CampaignTag";
import MessageMedia from "@/components/MessageMedia";
import { ACTIONS, GETTERS } from "@/util/constants";
import store from "@/store";
import { DropdownPlugin, ModalPlugin, VBTooltip } from "bootstrap-vue";
import api from "@/util/api";
import FileSaver from "file-saver";
import { INBOX_TYPES } from "../../util/constants";
import analytics from "@/util/analytics";
import _ from "lodash";

Vue.directive("b-tooltip", VBTooltip);
Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);

export default {
  name: "ScheduledMessagesCard",
  store,
  components: {
    CampaignTag,
    MessageMedia,
    MgIcon,
  },
  props: {
    scheduledMessage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: this.scheduledMessage.messageText,
      sharedInbox: this.scheduledMessage.groupAccount,
      isPersonalInbox: !this.scheduledMessage.groupAccount,
      scheduleTime: moment
        .tz(
          this.scheduledMessage.scheduleDate,
          this.$store.state.config.timeZone
        )
        .format("h:mm A"),
    };
  },
  computed: {
    slateQueryName() {
      return this.$store.getters[GETTERS.SLATE_QUERY_BY_ID](
        this.scheduledMessage.slateWebServiceQueryId
      );
    },
    reNxtListName() {
      return this.$store.getters[GETTERS.RE_NXT_LIST_BY_ID](
        this.scheduledMessage.raisersEdgeNxtListId
      );
    },
    connectFilterName() {
      return this.$store.getters[GETTERS.CONNECT_FILTER_BY_ID](
        this.scheduledMessage.connectFilterId
      );
    },
    media() {
      const media = this.scheduledMessage.mediaUris;
      if (media && media.length) {
        return this.scheduledMessage.mediaUris.map((m) => {
          return {
            url: m.cdnMediaUri,
            mimeType: m.mimeType,
          };
        });
      }
      return null;
    },
    radiusTargetName() {
      return this.$store.getters[GETTERS.RADIUS_TARGET_BY_ID](
        this.scheduledMessage.radiusTargetId
      );
    },
    userEmail() {
      return this.$store.getters[GETTERS.USER_EMAIL_BY_ID](
        this.scheduledMessage.staffId
      );
    },
    userNameDisplay() {
      if (this.$store.state.config.userId === this.scheduledMessage.staffId) {
        return "You";
      }
      return this.userName;
    },
    userName() {
      return this.$store.getters[GETTERS.USER_NAME_BY_ID](
        this.scheduledMessage.staffId
      );
    },
    gravatarBackgroundColor() {
      if (this.sharedInbox) {
        return this.sharedInbox.color;
      }
      if (this.$store.state.config.userId === this.scheduledMessage.staffId) {
        return "#396fb0";
      }
      return "#582062";
    },
    staticSegments() {
      return this.$store.state.scheduled.staticSegments;
    },
    autoUpdateSegments() {
      return this.$store.state.scheduled.autoUpdateSegments;
    },
    automatedSegments() {
      return this.$store.state.scheduled.automatedSegments;
    },
    totalSegments() {
      return (
        this.scheduledMessage.staticSegments.length +
        this.scheduledMessage.autoUpdateSegments.length +
        this.scheduledMessage.automatedSegments.length
      );
    },
    segmentNames() {
      let segmentsStr = "";

      if (
        this.scheduledMessage.segmentHistory &&
        this.scheduledMessage.wasProcessed
      ) {
        segmentsStr = this.scheduledMessage.segmentHistory;
      } else if (
        this.scheduledMessage.staticSegments.length >= 1 &&
        this.scheduledMessage.autoUpdateSegments.length >= 1
      ) {
        segmentsStr =
          this.scheduledMessage.staticSegmentNames +
          ", " +
          this.scheduledMessage.autoUpdateSegmentNames;
      } else if (this.scheduledMessage.autoUpdateSegments.length === 0) {
        segmentsStr = this.scheduledMessage.staticSegmentNames;
      } else if (this.scheduledMessage.staticSegments.length === 0) {
        segmentsStr = this.scheduledMessage.autoUpdateSegmentNames;
      }

      return segmentsStr;
    },
    automatedSegmentNames() {
      let segmentsStr = "";

      if (
        this.scheduledMessage.segmentHistory &&
        this.scheduledMessage.wasProcessed
      ) {
        segmentsStr = this.scheduledMessage.segmentHistory;
      } else if (
        this.scheduledMessage.staticSegments.length >= 1 &&
        this.scheduledMessage.automatedSegments.length >= 1
      ) {
        segmentsStr =
          this.scheduledMessage.staticSegmentNames +
          ", " +
          this.scheduledMessage.automatedSegmentNames;
      } else if (this.scheduledMessage.automatedSegments.length === 0) {
        segmentsStr = this.scheduledMessage.staticSegmentNames;
      } else if (this.scheduledMessage.staticSegments.length === 0) {
        segmentsStr = this.scheduledMessage.automatedSegmentNames;
      }

      return segmentsStr;
    },
    deletedSegments() {
      let deletedSegs = 0;
      let staticSegs = this.staticSegments;
      let autoSegs = this.autoUpdateSegments;
      let automatedSegments = this.automatedSegments;

      if (this.scheduledMessage.staticSegments.length > 0) {
        this.scheduledMessage.staticSegments.forEach(function (segment) {
          var segExists = _.find(staticSegs, { id: segment });
          if (!segExists) {
            deletedSegs++;
          }
        });
      }
      if (this.scheduledMessage.autoUpdateSegments.length > 0) {
        this.scheduledMessage.autoUpdateSegments.forEach(function (segment) {
          var segExists = _.find(autoSegs, { id: segment });
          if (!segExists) {
            deletedSegs++;
          }
        });
      }

      if (this.scheduledMessage.automatedSegments.length > 0) {
        this.scheduledMessage.automatedSegments.forEach(function (segment) {
          var segExists = _.find(automatedSegments, { id: segment });
          if (!segExists) {
            deletedSegs++;
          }
        });
      }
      return deletedSegs;
    },
    selectedCalendarDate() {
      return this.$store.state.scheduled.selectedCalendarDate;
    },
    recipientNames() {
      let recipientStr = "";

      if (
        this.scheduledMessage.recipientHistory &&
        this.scheduledMessage.wasProcessed
      ) {
        recipientStr = this.scheduledMessage.recipientHistory;
      } else {
        recipientStr = this.scheduledMessage.firstRecipient;
      }

      return recipientStr;
    },
    isSendLater() {
      return this.scheduledMessage.source === "Convo";
    },
  },
  methods: {
    async edit() {
      let campaign;
      let contacts;
      let inbox = {
        type: INBOX_TYPES.PERSONAL,
        userId: this.scheduledMessage.staffId,
      };
      let messageText = this.scheduledMessage.messageText;
      let schedule = {
        id: this.scheduledMessage.id,
        date: this.scheduledMessage.scheduleDate,
        name: this.scheduledMessage.name,
      };
      let restrictToAssignment = this.scheduledMessage.restrictToAssignment;
      let scheduledMessageId = this.scheduledMessage.id;
      let allSegments;
      let segments;
      let sendAsStaff = this.scheduledMessage.sendAsStaff;
      let smartMessage;
      let isScheduledMessage = true;
      let slateQuery;
      let reNxtList;

      if (this.scheduledMessage.campaignId) {
        campaign = {
          id: this.scheduledMessage.campaignId,
          name: this.$store.getters[GETTERS.GET_CAMPAIGN_NAME_BY_ID](
            this.scheduledMessage.campaignId
          ),
        };
      }

      if (this.scheduledMessage.slateWebServiceQueryId) {
        slateQuery = await this.getSlateQuery(
          this.scheduledMessage.slateWebServiceQueryId
        );
      }

      if (this.scheduledMessage.raisersEdgeNxtListId) {
        reNxtList = await this.getReNxtList(
          this.scheduledMessage.raisersEdgeNxtListId
        );
      }

      if (this.scheduledMessage.recipients) {
        contacts = await this.getContacts();
      }
      if (
        this.scheduledMessage.staticSegments.length ||
        this.scheduledMessage.autoUpdateSegments.length ||
        this.scheduledMessage.automatedSegments.length
      ) {
        allSegments = await this.getSegments();
        segments = allSegments.filter(Boolean);
      }

      if (this.sharedInbox) {
        inbox = {
          type: INBOX_TYPES.GROUP,
          groupId: this.scheduledMessage.groupAccount.id,
        };
      }

      if (this.scheduledMessage.smartMessage) {
        smartMessage = {
          ...this.scheduledMessage.smartMessage,
          message: this.scheduledMessage.messageText,
        };
      }
      if (!this.isSendLater) {
        this.$emit("edit", {
          campaign,
          contacts,
          inbox,
          messageText,
          restrictToAssignment,
          schedule,
          scheduledMessageId,
          segments,
          sendAsStaff,
          smartMessage,
          isScheduledMessage,
          slateQuery,
          reNxtList,
          modalTitle: "Edit Message",
        });
      } else {
        let url;
        window.localStorage.setItem(
          "sendLaterMessage",
          JSON.stringify({
            text: this.scheduledMessage.messageText,
            id: this.scheduledMessage.id,
            scheduleDate: this.scheduledMessage.scheduleDate,
          })
        );
        if (this.isPersonalInbox) {
          url = `/#/v2/personal/inbox/${this.scheduledMessage.recipients[0]}`;
        } else {
          url = `/#/v2/shared/${this.scheduledMessage.groupAccount.id}/inbox/${this.scheduledMessage.recipients[0]}`;
        }
        window.history.pushState({}, "", url);
      }
    },
    async getContacts() {
      return await Promise.all(
        this.scheduledMessage.recipients.map(async (id) => {
          let contact = await api.get(`/students/${id}`);
          return {
            id,
            tagText: contact.firstName
              ? `${contact.firstName} ${contact.lastName}`
              : contact.mobileNumberFormatted,
          };
        })
      );
    },
    async getSegments() {
      let autoUpdateSegments = this.scheduledMessage.autoUpdateSegments.map(
        (id) => {
          return {
            id,
            type: "Auto-Update",
          };
        }
      );
      let staticSegments = this.scheduledMessage.staticSegments.map((id) => {
        return {
          id,
          type: "Static",
        };
      });

      let automatedSegments = this.scheduledMessage.automatedSegments.map(
        (id) => {
          return {
            id,
            type: "Automated",
          };
        }
      );
      let segments = autoUpdateSegments.concat(
        staticSegments,
        automatedSegments
      );
      return await Promise.all(
        segments.map(async (seg) => {
          let segment;
          if (seg.type === "Automated") {
            segment = await api
              .get(`/segments/automated/${seg.id}`)
              .catch((err) => err);

            segment.conditions = segment.conditions
              ? JSON.parse(segment.conditions)
              : null;
          } else if (seg.type === "Static") {
            segment = await api
              .get(`/staticsegments/${seg.id}`)
              .catch((err) => err);
          } else {
            segment = await api
              .get(`/autoupdatesegments/${seg.id}`)
              .catch((err) => err);

            segment.conditions = segment.conditions
              ? JSON.parse(segment.conditions)
              : null;
          }
          segment.tagText = segment.name + " (" + seg.type + ")";
          segment.text = seg.tagText;
          segment.type = seg.type;

          if (segment.id) {
            return segment;
          } else {
            return null;
          }
        })
      );
    },
    async getSlateQuery(slateWebServiceQueryId) {
      const slateQuery = await api.get(
        `/slatequeries/${slateWebServiceQueryId}`
      );
      return slateQuery;
    },
    async getReNxtList(raisersEdgeNxtListId) {
      const reNxtLists = await api.get(`raisersEdgeNxt/constituent-lists`);
      const reNxtList = reNxtLists.find(
        (list) => list.id === raisersEdgeNxtListId
      );
      return reNxtList;
    },
    hashEmail(email) {
      return md5(email);
    },
    hideModal(id) {
      this.$bvModal.hide("sm-" + id);
    },
    saveCsv() {
      let id = this.scheduledMessage.id;
      api
        .get("scheduledmessages/" + id + "/recipients")
        .then(function (recipients) {
          var accumulator = "First Name, Last Name, Mobile Number\n";

          if (recipients.contacts) {
            recipients = recipients.contacts;
          }

          recipients.forEach(function (r, index) {
            var dataString = [r.firstName, r.lastName, r.mobileNumber].join(
              ","
            );
            accumulator +=
              index < recipients.length ? dataString + "\n" : dataString;
          });
          var data = new Blob([accumulator], {
            type: "text/csv;charset=utf-8",
          });
          FileSaver.saveAs(data, "recipients_" + id + ".csv");
        });
      analytics.track("Download Scheduled Message Recipients");
    },
    unscheduleMessage(id) {
      let request = api.post(`/messages/${id}/unschedule`, {});
      request
        .then(() => {
          this.$emit(
            "unschedule-message",
            "success",
            "The scheduled message has been deleted."
          );
          this.$store.dispatch(ACTIONS.UNSCHEDULE_MESSAGE, id);
          this.$store.dispatch(
            ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS,
            this.selectedCalendarDate
          );
          analytics.track("Deleted Scheduled Message");
        })
        .catch(() => {
          this.$emit(
            "unschedule-message",
            "danger",
            "There was an issue deleting this message."
          );
        });
    },
    viewSmartMessage(smartMessage) {
      this.$emit("view-smart-message", smartMessage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.time {
  min-width: 80px;
}

.card {
  padding: $panel-body-padding;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 30px;
}

.contents {
  background: $message-content-bg;
  border-radius: $border-radius;

  &.media-with-text {
    .message-media {
      margin-top: $line-height-computed/2;
    }
  }
  padding: 15px;
}

.smart-response-type {
  background-color: $message-content-bg;
  text-transform: uppercase;
  font-style: normal;
  padding: 2px;
}

.sent-tag {
  background-color: $message-content-bg;
  border-radius: $border-radius;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: $font-weight-light;
  padding: 0 5px;
  vertical-align: top;
  text-transform: uppercase;
}

.gravatar-container {
  margin: 3px 12px 0 4px;
  position: relative;
  min-width: 38px;
  height: 38px;
  .gravatar {
    width: 38px;
    height: 38px;
    position: relative;
  }
  .profile-letter-container {
    position: absolute;
    background-color: $blue;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    .profile-letter {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;
      position: absolute;
      top: 5px;
      width: 100%;
      text-align: center;
    }
  }
}

.segment-tooltip {
  border-bottom: 1px dotted #6c757d;
}

.card-menu {
  ::v-deep .dropdown-toggle {
    margin-top: -8px;
  }
  ::v-deep .dropdown-divider {
    margin: 0;
  }
}

.segments-deleted {
  color: #ff0000;
  margin-left: 5px;
  svg {
    margin-top: -2px;
  }
}

.min-width {
  min-width: 0;
}

.font-size-16 {
  font-size: 16px;
  line-height: 1;
}
</style>
