<template>
  <div class="h-100">
    <div id="sentBatches">
      <div
        class="table-responsive"
        v-if="sentBatches && sentBatches.length > 0"
      >
        <table
          class="table table-borderless table-striped table-hide-head align-middle"
        >
          <thead></thead>
          <tbody>
            <tr
              class="sent-batch-container d-flex flex-row"
              v-for="batch in sentBatches"
              @click="viewBatch(batch)"
              :key="batch.id"
            >
              <td class="message-details d-flex flex-column">
                <!-- for text-overflow trick in a responsive table cell check message-name CSS properties-->
                <span class="message-name-container">
                  <span class="message-name" v-if="batch.messageName">{{
                    batch.messageName
                  }}</span>
                  <span
                    class="message-name"
                    v-else-if="batch.smartMessageName"
                    >{{ batch.smartMessageName }}</span
                  >
                  <span
                    class="message-name"
                    v-else-if="batch.media && batch.media.length > 0"
                  >
                    <i class="fa fa-picture-o"></i>&ensp;<em
                      >Multimedia&ensp;</em
                    >
                    {{ batch.message }}</span
                  >
                  <span class="message-name" v-else>{{ batch.message }}</span>
                </span>
                <div class="d-flex">
                  To:&nbsp;
                  <em v-if="batch.totalRecipients === 1">{{
                    batch.student
                  }}</em>
                  <em
                    v-if="
                      batch.totalRecipients === 1 && batch.segments.length === 1
                    "
                    >{{ batch.segments[0] }}</em
                  >
                  <span
                    v-if="
                      batch.totalRecipients > 1 && batch.segments.length === 0
                    "
                    ><em>{{ batch.student }}</em> &amp;
                    {{ batch.totalRecipients - 1 }} more</span
                  >
                  <em
                    v-if="
                      batch.totalRecipients > 1 && batch.segments.length === 1
                    "
                    >{{ batch.segments[0] }}</em
                  >

                  <span
                    v-for="(segment, index) in batch.segments"
                    :key="`${segment}-${index}`"
                  >
                    <em
                      v-if="
                        batch.totalRecipients !== 0 && batch.segments.length > 1
                      "
                      >{{ segment }}</em
                    >
                    <span v-if="index !== batch.segments.length - 1"
                      >,&nbsp;</span
                    >
                  </span>

                  <em v-if="batch.filter">{{ batch.filter }}</em>
                  <em v-if="batch.target">{{ batch.target }}</em>
                </div>
                <div class="d-flex flex-row">
                  <div v-if="batch.status === 'Pending'" class="pending-bubble">
                    <div class="bubble-text">Pending</div>
                  </div>
                  <div>
                    <span v-if="batch.status !== 'Sending'"
                      >{{ totalRecipients(batch) }} recipient{{
                        batch.totalRecipients === 1 ? "" : "s"
                      }}</span
                    >
                    <span v-else-if="batch.status === 'Sending'"
                      >{{ totalSent(batch) }} of
                      {{ totalRecipients(batch) }} recipient{{
                        batch.totalRecipients === 1 ? "" : "s"
                      }}</span
                    >
                    <span v-if="quietHourCancellations(batch)"
                      >&nbsp;&bull;
                      {{ quietHourCancellations(batch) }} undelivered due to
                      quiet hours
                    </span>
                    <span>
                      <MgIcon
                        v-if="quietHourCancellations(batch)"
                        name="info-circle"
                        class="cancellationTooltip"
                        v-b-tooltip="{
                          title: quietHoursTooltipText_,
                          boundary: 'viewport',
                        }"
                      />
                    </span>
                    <span
                      v-if="quietHourCancellations(batch)"
                      id="cancelled-details-link"
                      @click="viewCancelledDetails(batch)"
                      >&nbsp;View details</span
                    >
                  </div>
                </div>
                <div v-if="batch.status === 'Sending'" class="sent-metric-bar">
                  <div
                    :style="{ width: totalSentPercentage(batch) + '%' }"
                  ></div>
                </div>
              </td>
              <div class="message-metrics d-flex align-items-center">
                <td class="cell-metric d-flex flex-column align-items-end">
                  <span
                    v-if="batch.status === 'Pending'"
                    class="cell-metric-value"
                    >-</span
                  >
                  <span v-else class="cell-metric-value">{{
                    totalSent(batch)
                  }}</span>
                  <span class="metric-info">Sent</span>
                </td>
                <td class="cell-metric d-flex flex-column align-items-end">
                  <span
                    v-if="batch.status === 'Pending'"
                    class="cell-metric-value"
                    >-</span
                  >
                  <div v-else>
                    <span class="cell-metric-value">
                      {{ deliveredCountPercentage(batch) }}%
                      <span class="metric-info">{{
                        deliveredCount(batch)
                      }}</span>
                    </span>
                  </div>
                  <span class="metric-info">Delivered</span>
                </td>
                <td class="cell-metric d-flex flex-column align-items-end">
                  <span
                    v-if="batch.status === 'Pending'"
                    class="cell-metric-value"
                    >-</span
                  >
                  <div v-else class="cell-metric-value">
                    <span> {{ totalReplyPercentage(batch) }}% </span>
                    <span class="metric-info"> {{ totalReplies(batch) }}</span>
                  </div>
                  <span class="metric-info">Replied</span>
                </td>
                <td
                  class="cell-metric d-flex flex-column align-items-end text-right"
                  v-html="getDate(batch.composeDate)"
                ></td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="w-66 page-content">
        <EmptyState
          :image="'/images/icon-paper-plane-duotone.svg'"
          :imageWidth="96"
          :imageAltText="'Message icon'"
          :description="'View sent message details such as delivery and reply rate, who received the message, and what the content was.'"
          :buttonText="'Compose Message'"
          @emptyStateAction="composeNewMessage()"
        ></EmptyState>
      </div>
    </div>
    <div class="pagination-container">
      <BButton
        class="btn btn-light text-dark"
        :disabled="skip_ === 0"
        v-on:click="getBatches('previous')"
        >Previous</BButton
      >
      <BButton
        class="btn btn-light text-dark"
        v-on:click="getBatches('next')"
        :disabled="lastPage"
        >Next</BButton
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import store from "@/store";
import { ACTIONS } from "@/util/constants";
import moment from "moment";
import { hashValues } from "@/util/hash";
import MgIcon from "@/components/MgIcon";
import { VBTooltip } from "bootstrap-vue";
import EmptyState from "@/components/EmptyState";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "InboxSentTab",
  store,
  components: {
    MgIcon,
    EmptyState,
  },
  data() {
    return {
      quietHoursTooltipText_:
        "Message sending is not allowed between 10:00 pm and 6:00 am to prevent disturbing recipients overnight.",
      activeInbox: null,
      config: {},
      skip_: 0,
      take_: 50,
    };
  },
  computed: {
    sentBatches() {
      return hashValues(this.$store.state.inbox.sentBatches);
    },
    totalBatches() {
      return this.$store.state.inbox.totalBatches;
    },

    lastPage() {
      return this.totalBatches <= this.take_ + this.skip_;
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_INBOX_SENT, {
      params: {
        skip: this.skip_,
        take: this.take_,
      },
    });
    this.$store.dispatch(ACTIONS.START_SIGNALR_INBOX_SENT);
  },
  methods: {
    quietHourCancellations(batch) {
      return _.get(batch, "quietHourCancellations", 0);
    },
    totalReplies(batch) {
      return batch.totalReplies.toLocaleString("en-us");
    },
    totalRecipients(batch) {
      return batch.totalRecipients.toLocaleString("en-US");
    },
    totalSent(batch) {
      return batch.totalSent.toLocaleString("en-US");
    },
    viewBatch(batch) {
      const url = batch.accountId
        ? `/#/v2/sent/${batch.batchId}/${batch.accountId}`
        : `/#/v2/sent/${batch.batchId}`;
      window.history.pushState({}, "", url);
    },
    viewCancelledDetails(batch) {
      let e = window.event;
      if (e.stopPropagation) e.stopPropagation();

      const url = batch.accountId
        ? `/#/v2/sent/${batch.batchId}/${batch.accountId}#cancelled`
        : `/#/v2/sent/${batch.batchId}#cancelled`;
      window.history.pushState({}, "", url);
    },
    getDate(value) {
      var date = moment.tz(
        moment.utc(value),
        this.$store.state.config.timeZone
      );

      return date.format("M/D/YY[<br />]h:mm A");
    },
    commaSeparateSegments(segments) {
      let csv = "";
      segments.forEach(function (segment, index) {
        if (segments.length === index + 1) {
          csv = csv + segment;
        } else {
          csv = csv + segment + ", ";
        }
      });
      return csv;
    },
    totalReplyPercentage(batch) {
      return Math.round((batch.totalReplies / batch.totalRecipients) * 100);
    },
    totalSentPercentage(batch) {
      return Math.round((batch.totalSent / batch.totalRecipients) * 100);
    },
    deliveredCount(batch) {
      return (batch.totalSent - batch.deliveryFailures).toLocaleString("en-US");
    },
    deliveredCountPercentage(batch) {
      return Math.round(
        ((batch.totalSent - batch.deliveryFailures) / batch.totalRecipients) *
          100
      );
    },
    getBatches(direction) {
      let options = {
        params: {
          skip:
            direction === "next"
              ? (this.skip_ += this.take_)
              : (this.skip_ -= this.take_),
          take: this.take_,
        },
      };
      this.$store.dispatch(ACTIONS.GET_INBOX_SENT, options);
    },
    composeNewMessage() {
      eventBus.dispatchEvent("open-compose-modal");
      analytics.track("Clicked Compose Message from Sent Tab");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.message-details {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  @include media-breakpoint-down(sm) {
    width: 25%;
  }
}
.message-metrics {
  flex: auto;
}
.message-metrics td {
  flex: 1 1 0;
  width: 0;
}
.message-name-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: flex;
  vertical-align: text-bottom !important;
}
.message-name {
  font-weight: 600;
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
  em {
    font-weight: 400;
  }
}
.message-name-container:after,
.message-name:after {
  content: "-";
  display: inline;
  visibility: hidden;
  width: 0;
}

#sentBatches {
  overflow: scroll;
  height: 100%;
}
.sent-batch-container {
  cursor: pointer;
}

.sent-metric-bar {
  border-radius: 2px;
  background-color: #d9d9d9;
  height: 5px;
  margin-top: 5px;
  overflow: hidden;
}
.sent-metric-bar > div {
  background-color: #1992d4;
  height: 100%;
  width: 0;
  transition: width 400ms 200ms ease-out;
}
.pending-bubble {
  padding: 4px 8px 4px 8px;
  border-radius: 12px;
  border: 1px solid #b4dff8;
  background-color: #e3f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.bubble-text {
  color: #035388;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.cell-metric-value {
  font-size: 20px;
}
.metric-info {
  color: #555;
  font-weight: 300;
  font-size: 14px;
}

.cancellationTooltip {
  position: relative;
  bottom: 2px;
}

#cancelled-details-link {
  color: $accent-alt-a11y;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}
.table {
  margin-bottom: 40px;
}

.w-66 {
  width: 66%;
}
</style>
